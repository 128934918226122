import * as React from 'react';
// import '../sass/main.scss';

import FeatherIcon from 'feather-icons-react';
import LayoutDefault from '../layouts/default';
import TextHeader from '../components/textHeader';

// markup
const CareersOverviewPage = () => {
  return (
    <>
      <LayoutDefault>
        <TextHeader
          title="Open positions"
          desc="Join us in helping create better experiences for our customers"
        />
        <section className="bg-white py-10">
          <div className="container px-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-10">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="mb-0">Administration</h2>
                  <div className="badge bg-primary-soft text-primary badge-marketing">
                    3 Openings
                  </div>
                </div>
                <hr className="mb-0" />
                <ul className="list-group list-group-flush list-group-careers">
                  <li className="list-group-item">
                    <a href="#!">Senior Manager, Risk Management</a>
                    <div className="small">Remote - US (San Francisco, CA)</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Operations Manager</a>
                    <div className="small">San Francisco, CA (HQ)</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Administrative Assistant</a>
                    <div className="small">Dallas, TX</div>
                  </li>
                </ul>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="mb-0">Engineering</h2>
                  <div className="badge bg-primary-soft text-primary badge-marketing">
                    5 Openings
                  </div>
                </div>
                <hr className="mb-0" />
                <ul className="list-group list-group-flush list-group-careers">
                  <li className="list-group-item">
                    <a href="#!">Senior Software Engineer</a>
                    <div className="small">Remote - US / Canada</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Software Engineer</a>
                    <div className="small">Remote - Global</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Data Scientist</a>
                    <div className="small">Remote - Global</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Front End Engineer</a>
                    <div className="small">San Francisco, CA (HQ)</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Technical Director</a>
                    <div className="small">San Francisco, CA (HQ)</div>
                  </li>
                </ul>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="mb-0">Sales</h2>
                  <div className="badge bg-primary-soft text-primary badge-marketing">
                    4 Openings
                  </div>
                </div>
                <hr className="mb-0" />
                <ul className="list-group list-group-flush list-group-careers">
                  <li className="list-group-item">
                    <a href="#!">Sales Support Coordinator</a>
                    <div className="small">Remote - US (San Francisco, CA)</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Senior Sales Representative</a>
                    <div className="small">Remove - US Northeast</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Paid Search Specialist</a>
                    <div className="small">Remote - Global</div>
                  </li>
                  <li className="list-group-item">
                    <a href="#!">Junior Sales Representative</a>
                    <div className="small">San Francisco, CA (HQ)</div>
                  </li>
                </ul>
                <h4 className="mb-4">
                  <div className="icon-stack bg-primary text-white me-2">
                    <FeatherIcon className="" icon="smile" />
                  </div>
                  Work happier
                </h4>
                <p className="mb-5">
                  Build amazing things with autonomy, self-direction, and a
                  healthy work-life balance. We offer flexible work schedules
                  for all of our employees and unlimited PTO. We also believe
                  that if a job allows for it, you should work wherever you’re
                  happiest.
                </p>
                <h4 className="mb-4">
                  <div className="icon-stack bg-primary text-white me-2">
                    <FeatherIcon className="" icon="trending-up" />
                  </div>
                  Keep growing
                </h4>
                <p>
                  Learn how you learn best. From books to conferences, you’ll
                  get a yearly budget for your individual learning and
                  development goals. If ebooks are more your style, you’ll get a
                  brand new Kindle on your first day.
                </p>
                <hr className="my-5" />
                <div className="card bg-light shadow-none">
                  <div className="card-body text-center p-5">
                    <h2>Internships</h2>
                    <p className="lead mb-4">
                      Are you currently enrolled at a college or university and
                      looking for hands-on experience while earning class
                      credit? Take a look at our internship opportunities!
                    </p>
                    <a className="btn btn-primary fw-500" href="#!">
                      Explore Internships
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="svg-border-rounded text-black">
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </section>
      </LayoutDefault>
    </>
  );
};

export default CareersOverviewPage;
